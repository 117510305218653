import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import InsideBanner from '../components/insidebanner'
import { Container, Header } from 'semantic-ui-react'
import bannerImage from '../images/baseball_field.jpg';
import photo1 from '../images/JimEisenreichMarlins2.jpg';

const AboutJim = () => (
  <Layout location="about">
    <SEO title="About Jim" />
    <InsideBanner headline="About Jim" src={bannerImage} style={{ backgroundSize: 'cover', backgroundPositionY: 'bottom' }} />
    <Container style={{ marginBottom: '35px' }} vertical>
      <Header as='h2' content="Jim's Story" />
      <img src={photo1} alt="Jim Eisenreich Florida Marlins Swinging" align="right" style={{ width: '38%', paddingLeft: '30px'}} />
      <p>When Florida Marlin Jim Eisenreich hit a homerun in the 1997 World Series, few watching would remember that Tourette Syndrome had almost taken away his dream to play baseball. But it didn't.</p>
      <p>Jim had a normal childhood and a loving family but at age 6 he began to exhibit some strange symptoms. He had tics and jerks, and couldn't quit blinking his eyes. His family accepted this behavior. But at school, where the social pressures can be enormous, he struggled.</p>
      <p>The doctor in his hometown of St. Cloud, Minnesota didn\'t understand and the teachers assumed that he could stop the strange behavior anytime he wished. Other children teased Jim. Even a junior high school coach made fun of him when he heard Jim clearing his throat uncontrollably. The reaction of outsiders made Jim feel that he must be crazy. He spent a lot of time alone, the only safe place for a child who feels different.</p>
      <p>Despite Jim's feelings of being different, one thing that saved him was sports. The same kids who had teased him were the first to pick him for the after school games. Jim was the best player in the neighborhood and the other kids knew it. After a successful high school and college baseball career, the Minnesota Twins drafted Jim. In his 1982 rookie season, he hit .303 as the center fielder. It was a dream come true, but his troubles were not over.</p>
      <p>Two months into the season, Jim began to feel embarrassed about his tics, which made them even worse. They became so bad that he had to come off the field in the middle of an inning. This happened three nights in a row at Boston's Fenway Park. When the team returned to Minneapolis, the team doctor checked him into a hospital.</p>
      <p>A diagnosis was difficult to determine. The doctors disagreed about his problem. Some thought it was psychological, but Jim was sure his mental health was not the problem. Finally after years of testing, Jim was diagnosed with Tourette Syndrome. The disorder was brought under control with medication and Jim was available to play again. In 1986, the Kansas City Royals picked up the waiver on Jim's contract for one-dollar.</p>
      <p>Jim's move to Kansas City and his return to baseball changed his life. In 1989 Jim was named the Royals most valuable player while playing with other baseball greats including George Brett and Bo Jackson.</p>
      <p>Jim went on to play in two World Series. The first was with the National League champion Philadelphia Phillies in 1993 and then with the 1997 World Series Champion Florida Marlins. These events brought Jim and Tourette Syndrome to a national level and planted the seeds of what he really wanted to do with his life.</p>
      <p>In baseball stadiums all around the country, Jim speaks to children and their families about his story. He speaks from experiences that were painful and he speaks from his heart. He has forgiven those who made fun of him and in turn ask them for their help in getting the message out about Tourette Syndrome.</p>
      <p>By establishing the Jim Eisenreich Foundation for Children with Tourette Syndrome, Jim has found a way to help others achieve their dreams.</p>
    </Container>
  </Layout>
)

export default AboutJim
