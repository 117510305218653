import PropTypes from 'prop-types'
import React from 'react'
import { Segment, Container, Header } from 'semantic-ui-react'

const InsideBanner = ({ headline, src }) => (
    <Segment
            inverted
            textAlign='center'
            vertical
            className="inside-banner"
            style={{ background: 'radial-gradient( rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.10)), url(' + src + ') no-repeat', backgroundSize: 'cover' }}
          >
        <Container text>
            <Header
                as='h1'
                content={headline}
                inverted
            />
        </Container>
    </Segment>
  )
  
  InsideBanner.propTypes = {
    headline: PropTypes.string,
    src: PropTypes.string
  }

  export default InsideBanner