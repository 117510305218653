import PropTypes from 'prop-types'
import React from 'react'
import { Container, Menu, Image } from 'semantic-ui-react'
import src from '../images/JEF_Logo.png'

const Header = ({ location }) => (
  <Container>
    <Menu pointing={true} secondary={true} size='large'>
      <Image src={src} size='medium' className="logo" />
      <div className="right item">
          <Menu.Item as='a' href='/' active={location === 'home'}>Home</Menu.Item>
          <Menu.Item as='a' href='/about-jim' active={location === 'about'}>About Jim</Menu.Item>
          <Menu.Item as='a' href='/questions-about-tourettes' active={location === 'questions'}>Questions about TS</Menu.Item>
          <Menu.Item as='a' href='/resources' active={location === 'resources'}>Resources</Menu.Item>
          <Menu.Item as='a' href='/get-connected' active={location === 'connected'}>Get Connected</Menu.Item>
          <Menu.Item as='a' href='/contact-us' active={location === 'contact'}>Contact Us</Menu.Item>
        </div>
      </Menu>
    </Container>
)

Header.propTypes = {
  location: PropTypes.location
}

Header.defaultProps = {
  location: ``
}

export default Header
