import React from 'react'
import { Icon } from 'semantic-ui-react';

const Footer = () => (
    <div className="ui inverted vertical footer segment">
        <div className="ui container">
            <div className="ui stackable inverted divided equal height stackable grid">
                <div className="five wide column">
                    <h4 className="ui inverted header">
                        Contact Us
                    </h4>
                    <div className="ui inverted link list">
                        <p>
                            <div>Jim Eisenreich Foundation</div>
                            <div>Post Office Box 953</div>
                            <div>Blue Springs, MO 64013</div>
                        </p>
                        <p><a className="item" href="mailto:Foundation@Tourettes.org">Foundation@Tourettes.org</a></p>
                        <p>
                        { /*<Icon as="i" name="facebook" link="#" size="large" />&nbsp;&nbsp;
                            <Icon as="i" name="twitter" link="#" size="large" />&nbsp;&nbsp;
                            <Icon as="i" name="instagram" link="#" size="large" /> */ }
                        </p>
                    </div>
                </div>
                <div className="four wide column">
                    <h4 className="ui inverted header">
                        Resources
                    </h4>
                    <div className="ui inverted link list">
                        <a className="item" href="/about-jim">About Jim</a>
                        <a className="item" href="#">Questions about TS</a>
                        <a className="item" href="#">Resources</a>
                        <a className="item" href="#">Get Connected</a>
                        <a className="item" href="#">Contact Us</a>
                    </div>
                </div>
                <div className="seven wide column">
                    { /*<h4 className="ui inverted header">
                        Footer Header
                    </h4>
                    <p>
                        Extra space for a call to action inside the footer that could help re-engage users.
                    </p> */}
                </div>
            </div>
        </div>
    </div>
)

export default Footer